import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import CopyBlock from "../components/Copy/CopyBlock"


class PrivacyPolicyPage extends React.Component {

  render() {
  
    return (

      <Layout>
        <SEO
          title={"Privacy Policy - Messi vs Ronaldo"}
          canonicalPath={`/privacy-policy/`}
        />

        <h1>Privacy Policy</h1>      

        <div class="mt-8 lg:mt-12">
          <CopyBlock>

            <p>This website does collect any personal or identifiable details. This website uses cookies for the sole purpose of improving the performance of our website, as explained below.</p>

            <h2>Cookies</h2>
            
            <p>By using our website you consent to the usage of data captured by the use of cookies. Cookies allow us to do multiple things to enhance and improve your browsing experience on our website. If you wish to turn off cookies, please adjust your browser settings. Our website will continue to function without cookies.</p>

<p>We use cookies to track visitors to our website; these details are in no way personal or identifiable details and will never be shared. Our cookies are for the sole purpose of improving the performance of our website for you, the user; this includes allowing us to geo-target our users, to make websites more personal and relevant to you.</p>

<h3>Google Analytics</h3>
<p>Page views, source and time spent on website are part of the user website activities information we can see with this cookie. This information cannot be tracked back to any individuals as it is displayed as depersonalised numbers; this is in order to help protect your privacy whilst using our website.</p>

<p>Using Google Analytics we can take account of which content is popular, helping us to provide you with reading and viewing materials which you will enjoy and find useful in the future.</p>

<p>We also use Google Analytics Remarketing cookies to display adverts on third party websites to our past site users, based on their past visits. The data we collect will only be used in accordance with our own privacy policy and Google’s privacy policy.</p>

<h2>Third-Party Websites</h2>

<p>We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our website.</p>

<p>We may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding such third-parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services or applications. The inclusion of a link towards a third-party website, service, or application does not imply endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third-party websites. Any data collected by third parties is not covered by this Privacy Notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that be linked to from our website. You should review the policies of such third parties and contact them directly to respond to your questions.</p>

<h2>Ezoic Services</h2>

<p>This website uses the services of Ezoic Inc. (“Ezoic”). Ezoic’s privacy policy is <a className="text-highlight underline" href="https://www.ezoic.com/privacy-policy/">here</a>. Ezoic may employ a variety of technologies on this website, including to display advertisements and enable advertising to visitors of this website. For additional information about Ezoic’s advertising partners, please see Ezoic’s Advertising Partner Page <a className="text-highlight underline" href="https://www.ezoic.com/privacy-policy/ad-partners/">here</a>.</p>

          </CopyBlock>
        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export default PrivacyPolicyPage
